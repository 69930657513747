<template>
  <div class="edu-frame">
    <edu-nav-bar title="任课计划" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <div  v-for="(m, index) in masters" :key="index" class="edu-group class-item">
        <div class="class-detail" @click="toadd(m)">
          <van-row>
            <van-col span="8">序号:{{m.forder}}</van-col>
            <van-col span="20">班级名称:{{m.className}}</van-col>
          </van-row>
          <van-row>
            <van-col span="12">学期:{{m.classTypeStr}}</van-col>
            <van-col span="12">系名:{{m.depName}}</van-col>
          </van-row>
          <van-row>
            <van-col span="24">上课时间:{{m.classTime}}</van-col>
          </van-row>
          <van-row>
            <van-col span="8">审核人:{{m.checkBy}}</van-col>
            <van-col span="16">审核状态:{{m.statusStr}}</van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Row, Col, Dialog} from "vant";
import EduNavBar from "@/components/EduNavBar";
import Tools from "@/api/Tools";
import XEUtils from 'xe-utils';
import TeachingPlanApi from "@/api/TeachingPlanApi";

export default {
  components: {
    EduNavBar,
    VanRow: Row,
    VanCol: Col,
    [Dialog.Component.name]: Dialog.Component,
  },
  filters: {
    filterDate(date) {
      return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
    }
  },
  data() {
    return {
      show: false,
      submitting: false,
      cust: Tools.getCurCust(),
      masters:[],
      addParams:{},
    }
  },
  methods: {
    list(){
      this.loading = true;
      var pageCondition = {page: 1, pageSize: 1000, total: 0, condition: {}}
      pageCondition.condition.teacherId = this.cust.custId;
      pageCondition.condition.loginType = this.cust.loginType
      TeachingPlanApi.listPlan(pageCondition).then(response => {
        this.loading = false
        this.masters = response.res.list;
      }).catch(()=>{
        this.loading = false
      })
    },
    toadd(m){
      console.log(m)
      this.$router.push({ name: 'rkjhedit', query: { classId: m.classId } })
      /* if(null == m.teachingPlanMasterId){
        Dialog.confirm({
          title: '提示',
          message: '【' + m.className + '】教学计划尚未生成,是否确定生成?',
        }).then(() => {
          this.addParams.createBy = this.cust.custId;
          this.addParams.createUserType = 2;
          this.addParams.classId = m.classId;
          TeachingPlanApi.add(this.addParams).then(response => {
            if(response.code == 100){
              this.list();
              this.$dialog.alert({message: "操作成功"})
            }else{
              this.$dialog.alert({message: response.msg})
            }
          })
        }).catch(() => {
        });
      }else{
        this.$router.push({name: 'jxjhtx', query: {teachingPlanMasterId:m.teachingPlanMasterId}})
      } */
    },
  },
  mounted() {
    this.list();
  }
}
</script>
<style scoped>
</style>
